<template>
  <div>
    <!--begin::User-->
    <div>
          <h3 class="card-label m-0">
            {{ $t('open_stock_items.open_stock_items') }}
            <span class="text-muted pt-2 font-size-sm d-block"></span>
          </h3>

      <div class="row">
        <div class="col-lg-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <label>{{ $t('open_stock_items.inventory') }}<span class="text-danger">*</span></label>
                <div class="input-group">
                  <multiselect v-model="inventory"
                               :placeholder="$t('open_stock_items.inventory')"
                               label="name"
                               track-by="id"
                               :options="inventories"
                               :multiple="false"
                               :class="validation && validation.inventory_id ? 'is-invalid' : ''"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false">
                  </multiselect>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/settings/inventories/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                  </div>
                </div>
                <span v-if="validation && validation.inventory_id" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.inventory_id[0] }}
              </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('open_stock_items.applied_date') }}</label>
                <input type="date" v-model="data.applied_date" class="form-control" :class="validation && validation.applied_date ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.applied_date" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.applied_date[0] }}
              </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('balance_sheet.financial_year') }}<span class="text-danger">*</span></label>
                <multiselect v-model="financial_year"
                             :placeholder="$t('balance_sheet.financial_year')"
                             label="name"
                             track-by="id"
                             :options="financial_years_list"
                             :multiple="false"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false">
                </multiselect>
                <span v-if="validation && validation.financial_year_id" class="fv-plugins-message-container invalid-feedback">
                      {{ validation.financial_year_id[0] }}
                  </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('open_stock_items.status') }}<span class="text-danger">*</span></label>
                <select name="status" id="status" v-model="data.status" class="custom-select" disabled>
                  <option v-for="row in status_list" :value="row.id" :key="row.id">{{ row.title }}</option>
                </select>
                <span v-if="validation && validation.status" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.status[0] }}
              </span>
              </div>
            </div>
          </div>
        </div>
      </div>

        <div class="row mt-5">
          <div class="col-md-12">
            <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
              <h6 class="mt-2">{{ $t('open_stock_items.items') }}</h6>
              <button type="button" class="btn btn-primary" @click="addItemRowToList">{{ $t('add_more') }}</button>
            </div>
<!--            <div class="table-responsive bg-white">-->
            <div class=" bg-white">
              <table class="table table-row-bordered table-custom-padding" @keyup.alt.enter="addItemRowToList" @keyup.alt.46="removeItemRowFromList">
                <thead>
                <tr>
                  <th width="250px">{{ $t('items.item_details') }}</th>
                  <th width="300px">
                    <div class="d-flex justify-content-between">
                      <span>{{ $t('credits.item') }}</span>
                      <a href="javascript:;" class="btn btn-primary btn-sm p-1" @click="showItemModal">
                        <b-icon icon="plus"></b-icon>
                      </a>
                    </div>
                  </th>
                  <th width="200px">{{ $t('open_stock_items.unit') }}</th>
                  <th width="200px">{{ $t('open_stock_items.quantity') }}</th>
                  <th width="200px">{{ $t('open_stock_items.min_quantity') }}</th>
                  <th width="200px">{{ $t('open_stock_items.max_quantity') }}</th>
                  <th></th>
                </tr>

                </thead>
                <tbody>
                <tr v-for="(row, index) in items_list" :key="index"  :class="getClassValidationRepeaterByIndex(index)" :style="{ background: row.is_added_inv_statistics == 0 && data.status == 2 ? 'rgb(255 226 207)' : '' }" >

                  <td>
                    <a :id="'popover-target-'+index" href="javascript:;" v-if="row.item" class="d-flex cursor-pointer">
                      <img :src="row.item.image_url" style="width: 70px; height: 70px;" alt="">
                      <div class="pl-2 pr-2 text-dark">
                        <p class="mb-1" v-if="row.item.sku_code">{{ $t('items.sku_code') }}: {{ row.item.sku_code }}</p>
                        <p class="mb-1" v-if="row.item.name">{{ $t('items.name') }}: {{ row.item.name }}</p>
                        <p class="mb-1" v-if="row.item.sale_price">{{ $t('items.sale_price') }}: {{ row.item.sale_price }}</p>
                        <p class="mb-1" v-if="row.item.purchase_price">{{ $t('items.purchase_price') }}: {{ row.item.purchase_price }}</p>
                        <p class="mb-1" v-if="row.item.sum_available_qty">{{ $t('items.sum_available_qty') }}: {{ row.item.sum_available_qty }}</p>
                      </div>
                    </a>
                  </td>
                  <td>
                    <div class="d-flex search-item-group"  v-b-tooltip.hover.leftbottom :title="$t('type_at_least_three_letters') + ' ' + $t('credits.item')">
                      <select name="" id="" v-model="row.f_by" class="custom-select" style="width: 100px;">
                        <option value="name" selected>{{ $t('items.name') }}</option>
                        <option value="sku_code">{{ $t('items.sku_code') }}</option>
                      </select>
                      <multiselect v-model="row.item"
                                   :placeholder="$t('credits.item')"
                                   :label="row.f_by? row.f_by:'name'"
                                   track-by="id"
                                   :options="items"
                                   :multiple="false"
                                   @input="selectItem(index)"
                                   :taggable="false"
                                   :show-labels="false"
                                   :show-no-options="false"
                                   :show-no-results="false"
                                   :internal-search="false"
                                   @search-change="getItems(row.f_by, $event)">
                      </multiselect>
                    </div>

                    <span v-if="validation && validation[`items.${index}.item`]" class="fv-plugins-message-container invalid-feedback">
                        {{ validation[`items.${index}.item`][0] }}
                      </span>
                  </td>
                  <td>
                    <multiselect v-model="row.unit" v-b-tooltip.hover.leftbottom :title="$t('type_at_least_three_letters') + ' ' + $t('sales_invoices.unit')"
                                 :placeholder="$t('open_stock_items.unit')"
                                 label="name"
                                 track-by="id"
                                 :options="(row.item && row.item.units) ? row.item.units:[]"
                                 :multiple="false"
                                 :taggable="false"
                                 :show-labels="false"
                                 :show-no-options="false"
                                 :show-no-results="false"
                                 @input="setValueUnit(index)"
                                 :class="validation && validation[`items_list.${index}.unit`] ? 'is-invalid' : ''"
                    >
                    </multiselect>
                    <span v-if="validation && validation[`items_list.${index}.unit`]" class="fv-plugins-message-container invalid-feedback">
                            {{ validation[`items_list.${index}.unit`][0] }}
                          </span>

                    <input v-model="row.units_number" v-if="row.item && row.item.unit && row.unit && row.item.unit.id != row.unit.id" min="0" @input="setValue(index)" type="number" class="form-control mt-3">
                  </td>
                  <td>
                    <input v-model="row.qty"  @input="setValue(index)" type="number" class="form-control" :disabled="row.item && row.item.unit && row.unit && row.item.unit.id != row.unit.id" :class="validation && validation[`items.${index}.qty`] ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation[`items.${index}.qty`]" class="fv-plugins-message-container invalid-feedback">
                        {{ validation[`items.${index}.qty`][0] }}
                      </span>
                  </td>
                  <td>
                    <input v-model="row.min_qty" @input="setValue(index)" type="number" class="form-control" :class="validation && validation[`items.${index}.min_qty`] ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation[`items.${index}.min_qty`]" class="fv-plugins-message-container invalid-feedback">
                        {{ validation[`items.${index}.min_qty`][0] }}
                      </span>
                  </td>
                  <td>
                    <input v-model="row.max_qty" @input="setValue(index)" type="number" class="form-control" :class="validation && validation[`items.${index}.max_qty`] ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation[`items.${index}.max_qty`]" class="fv-plugins-message-container invalid-feedback">
                        {{ validation[`items.${index}.max_qty`][0] }}
                      </span>
                  </td>

                  <td>
                    <template v-if="items_list.length > 1 && data.status == 2">
                      <v-icon style="color: #dc3545;" small v-if="row.id == null" @click="removeItemRowFromList(index)">mdi-delete</v-icon>
                    </template>
                    <template v-else-if="items_list.length > 1">
                      <v-icon style="color: #dc3545;" small @click="removeItemRowFromList(index)">mdi-delete</v-icon>
                    </template>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="pl-0 pr-0">
          <div class="row">
            <div class="col-lg-6 mt-10">
              <button type="button" class="btn btn-primary mr-2" @click="save($event)">{{ $t('save') }}</button>
            </div>
          </div>
        </div>



    </div>
    <!--end::User-->

    <b-modal ref="modalItem" size="lg" hide-footer :title="$t('items.add_new_item')">
      <item-form @hide-modal="hideItemModal()" @handling-data="getDataAfterCreateNewItem"></item-form>
    </b-modal>

  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import {mapGetters, mapState} from "vuex";
import ItemForm from "@/view/content/forms/ItemForm";
import timeZoneStructure from "@/core/config/mix/timeZoneStructure";

export default {
  name: "from-open-stock-items",
  components: {'item-form': ItemForm,},
  data() {
    return {
      mainRoute: 'items/item-open-stocks',
      mainRouteDependency: 'base/dependency',
      idEdit: this.$route.params.id ? this.$route.params.id : null,
      inventories: [],
      items: [],
      status_list: [],


      //     $table->unsignedBigInteger('item_id');
      // $table->unsignedBigInteger('inventory_id');
      // $table->integer('min_qty')->nullable();
      // $table->integer('max_qty')->nullable();
      // $table->date('applied_date')->nullable();
      // $table->integer('qty');
      // $table->tinyInteger('status')->default(0)->comment('0>pending, 1>confirm, 2>cancel, 3>closed');

      data: {
        inventory_id: null,
        // item_id: null,
        // qty: null,
        // min_qty: null,
        // max_qty: null,
        financial_year_id: null,
        applied_date: null,
        status: 1,
      },
      isEditing: false,
      validation: null,
      inventory: null,
      item: null,

      items_list_form: {id: null, item: null, item_id: null, qty: null, min_qty: null, max_qty: null,units_number: null, unit: null},
      items_list: [],
      financial_years_list: [],
      financial_year: null,
      repeater_validation: [],

    };
  },
  computed: {
    ...mapState({user_personal_info: state => state.profile.user_personal_info}),
    ...mapGetters(["currentUserPersonalInfo"]),
  },
  watch: {
    financial_year: function (val) {
      if (val) {
        this.data.financial_year_id = val.id;
      }
    },

    inventory: function (val) {
      if (val) {
        this.data.inventory_id = val.id;
      } else {
        this.data.inventory_id = null;
      }
    },
    // item: function (val) {
    //   if (val) {
    //     this.data.item_id = val.id;
    //   } else {
    //     this.data.item_id = null;
    //   }
    // },
  },
  methods: {
    ...timeZoneStructure,
    getClassValidationRepeaterByIndex(index) {
      if (index != null) {
        if (this.repeater_validation.includes(index))
            // return 'tr-validation-error';
          return 'border-validation';
      }
      return '';
    },
    validationQTYChecking(_status = true) {
      let _main_validation_status = false;
      this.validation = [];
      this.items_list.forEach((row, index) => {
        let _validation_status = false;

        if (parseFloat(row.qty) <= 0) {
          this.validation[`items.${index}.qty`] = [];
          this.validation[`items.${index}.qty`][0] = this.$t('the_value_must_not_be_less_than_0');
          _validation_status = true;
        }else if(!row.qty && _status){
          this.validation[`items.${index}.qty`] = [];
          this.validation[`items.${index}.qty`][0] = this.$t('the_value_must_not_be_less_than_0');
          _validation_status = true;
        }
        if (parseFloat(row.min_qty) <= 0) {
          this.validation[`items.${index}.min_qty`] = [];
          this.validation[`items.${index}.min_qty`][0] = this.$t('the_value_must_not_be_less_than_0');
          _validation_status = true;
        }else if(!row.min_qty && _status){
          this.validation[`items.${index}.min_qty`] = [];
          this.validation[`items.${index}.min_qty`][0] = this.$t('the_value_must_not_be_less_than_0');
          _validation_status = true;
        }
        if (parseFloat(row.max_qty) <= 0) {
          this.validation[`items.${index}.max_qty`] = [];
          this.validation[`items.${index}.max_qty`][0] = this.$t('the_value_must_not_be_less_than_0');
          _validation_status = true;
        }else if(!row.max_qty && _status){
          this.validation[`items.${index}.max_qty`] = [];
          this.validation[`items.${index}.max_qty`][0] = this.$t('the_value_must_not_be_less_than_0');
          _validation_status = true;
        }
        if(!row.item && _status){
          this.validation[`items.${index}.item`] = [];
          this.validation[`items.${index}.item`][0] = this.$t('the_item_field_must_be_required');
          _validation_status = true;
        }
        if(!row.unit && _status){
          this.validation[`items_list.${index}.unit`] = [];
          this.validation[`items_list.${index}.unit`][0] = this.$t('the_unit_field_must_be_required');
          _validation_status = true;
        }


        if (_validation_status && _status){
          let _index = this.repeater_validation.indexOf(index);
          if (_index < 0) {
            this.repeater_validation.push(index);
          }
        }else{
          let _index = this.repeater_validation.indexOf(index);
          if (_index > -1) {
            this.repeater_validation.splice(_index, 1);
          }
        }

      });
      if (this.repeater_validation.length > 0){
        _main_validation_status = true;
      }
      if (_main_validation_status){
        let _items = [...this.items_list];
        this.items_list = [];
        this.items_list = _items;
        if (_status)
          this.$errorAlertMessage(this.$t('unable_to_send_due_to_data_entry_error'));
      }
      return _main_validation_status;

    },
    refactorValidation(){
      this.validationQTYChecking(false);
    },
    save(event) {
      if (this.validationQTYChecking()) {
        event.preventDefault();
        return false;
      } else {
        if (this.isEditing) {
          if (this.checkNullItemId()) {
            this.checkNewItem();
          } else {
            this.update();
          }
        } else {
          this.create();
        }
      }
    },

    checkNullItemId() {
      if (this.data.status == 1){
        return false;
      }
      let status = false;
      this.items_list.map((item) => {
          if(item.id == null || item.is_added_inv_statistics == 0 && this.data.status == 2){
            status = true;
          }
        });
        return status;
    },

    checkNewItem() {
        this.$confirmAlertSave(this.$t('do_you_want_the_store_to_be_affected_by_new_products_and_quantities'), this.update,true);
    },

    create() {
      ApiService.post(this.mainRoute, {
        ...this.data,
        items: this.items_list,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/items/open-stock-items');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    update(is_added_inv_statistics = false) {
      ApiService.put(this.mainRoute + '/' + this.idEdit, {
        ...this.data,
        items: this.items_list,
        is_added_inv_statistics : is_added_inv_statistics
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/items/open-stock-items');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    getData() {
      ApiService.get(this.mainRoute + '/' + this.idEdit).then((response) => {
        this.isEditing = true;
        this.data.inventory_id = response.data.data.inventory_id;
        this.data.applied_date = response.data.data.applied_date;
        this.data.status = response.data.data.status;
        this.inventory = response.data.data.inventory;
        this.financial_year = response.data.data.financial_year;
        this.items_list = response.data.data.items_list;

        if (response.data.data.items_list && response.data.data.items_list.length <= 0) {
          this.addItemRowToList();
        }
      });
    },

    getItems(f_by = null, filter = null) {
      let _f_by = f_by?f_by:'name';
      if (filter && filter.length >= 3 && _f_by)
        ApiService.get(this.mainRouteDependency + "/items", {params: {[_f_by]: filter}}).then((response) => {
          this.items = response.data.data;
        });
    },

    getInventories() {
      ApiService.get(this.mainRouteDependency + "/inventories").then((response) => {
        this.inventories = response.data.data;
      });
    },
    getFinancialYears() {
        ApiService.get(this.mainRouteDependency + "/financial_years").then((response) => {
            this.financial_years_list = response.data.data.map((row) => {
                return {id: row.id, name: row.start_date + ' - ' + row.end_date};
            });
            if(!this.idEdit){
              this.financial_year = this.financial_years_list[0];
            }
        });
    },
    getStatusList() {
      ApiService.get(this.mainRouteDependency + "/status").then((response) => {
        this.status_list = response.data.data;
      });
    },
    showItemModal() {
      this.$refs['modalItem'].show()
    },
    hideItemModal() {
      this.$refs['modalItem'].hide();
    },
    getDataAfterCreateNewItem(object) {
      // let promise = this.getItems();
      // Promise.all([promise]).then(() => {
        if (object) {
          this.items_list[0].item = object;
        }
      // })
    },

    addItemRowToList() {
      this.items_list.unshift(this.items_list_form);
      this.items_list_form = {id: null, item: null, item_id: null, qty: null, min_qty: null, max_qty: null,units_number: null, unit: null};
      this.refactorValidation();
    },
    removeItemRowFromList(index = 0) {
        if (this.items_list.length > 1) {
            this.items_list.splice(index, 1);
          this.refactorValidation();
        }
    },
    selectItem(index) {
      this.items_list[index].item_id = this.items_list[index].item ? this.items_list[index].item.id : null;

      this.items_list[index].unit = null;

      if (this.items_list[index].item && this.items_list[index].item.units && this.items_list[index].item.units.length) {
        this.items_list[index].unit = this.items_list[index].item.units.find(row => row.is_main);
      }

    },
    setValueUnit(index) {
      this.items_list[index].qty = 0;
      this.items_list[index].units_number = 0;
      this.setValue(index);
    },

    setValue(index) {
      this.zeroForNegative();
      if (this.items_list[index].item && this.items_list[index].unit && this.items_list[index].item.unit) {
        if (this.items_list[index].item.unit.id != this.items_list[index].unit.id) {
          if (this.items_list[index].item.is_min) {
            this.items_list[index].qty = ((this.items_list[index].unit.number ? parseFloat(this.items_list[index].unit.number) : 1) * (this.items_list[index].units_number ? parseFloat(this.items_list[index].units_number) : 1)).toFixed(3);
          } else {
            this.items_list[index].qty = ((this.items_list[index].units_number ? parseFloat(this.items_list[index].units_number) : 1) / (this.items_list[index].unit.number ? parseFloat(this.items_list[index].unit.number) : 1)).toFixed(3);
          }
        } else {
          this.items_list[index].units_number = 1;
        }
      }
    },

    zeroForNegative() {
      this.items_list = this.items_list.map((row) => {


        if (String(row.qty).length > 9) {
          row.qty = String(row.qty).slice(0, 9);
        }
        if (String(row.discount_value).length > 9) {
          row.discount_value = String(row.discount_value).slice(0, 9);
        }

        if (isNaN(row.qty)) {
          row.qty = 0;
        } else if (parseFloat(row.qty) < 0) {
          row.qty = 0;
        }

        if (isNaN(row.units_number)) {
          row.units_number = 0;
        } else if (parseFloat(row.units_number) < 0) {
          row.units_number = 0;
        }

        if (isNaN(row.min_qty)) {
          row.min_qty = 0;
        } else if (parseFloat(row.min_qty) < 0) {
          row.min_qty = 0;
        }

        if (isNaN(row.max_qty)) {
          row.max_qty = 0;
        } else if (parseFloat(row.max_qty) < 0) {
          row.max_qty = 0;
        }


        return row;

      });
    },

    defaultDataForUser(){
      ApiService.get(this.mainRouteDependency +"/default_data_for_user").then((response) => {
        this.data.inventory_id = response.data.data.inventory_id;
        this.inventory = response.data.data.inventory;
      });
    }

  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.open_stock_items"), route: '/items/open-stock-items'}, {title: (this.idEdit ? this.$t('update') : this.$t('create'))}]);
    // this.getItems();
    this.getInventories();
    this.getStatusList();
    this.getFinancialYears();

    if (this.idEdit) {
      this.getData();
    } else {
      // this.data.applied_date = new Date().toISOString().slice(0, 10);
      this.getTimeZone().then((res)=>{
        this.data.applied_date = res;
      });
      this.addItemRowToList();
      this.defaultDataForUser();
    }

  },
};
</script>


